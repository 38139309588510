import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Spinner } from '@fluentui/react/lib/Spinner';
import * as auth from '../../../app/Auth/Auth';

import { saveQueryRequest } from '../../../app/Query/actions';
import { clearTopicDescription, searchRequest, selectableTabs, setQuery } from '../../../app/Search/actions';

import { isAuthorizationBasedOnUserList } from '../../../app/Auth/Auth';
import { showModal } from '../../../app/Modal/actions';
import { nationalSearchRequest } from '../../../app/NationalSearch/actions';
import { partnerSearchRequest } from '../../../app/PartnerSearch/actions';
import { searchProjectsRequest } from '../../../app/Projects/actions';
import { fetchSavedDocumentsRequest } from '../../../app/Saved/actions';
import { IState } from '../../../reducers';
import { store } from '../../../store';
import SearchField from './SearchField';
import './SearchInput.css';
import { AccessNotAllowed, EmailNotVerified, TrialEnded, TrialEnding } from './SearchInputMessages';

interface IPropsFromState {
  user: any;
  searchResults: any;
  modal: any;
}

interface IComponentState {
  formErrors: { searchInput: string };
  searchInputValid: boolean;
}

interface IPropsFromDispatch {
  handleChange: (v: any) => void;
}

type AllProps = IPropsFromState & IPropsFromDispatch & RouteComponentProps;

class SearchInputComponent extends React.Component<AllProps, IComponentState & IPropsFromState> {
  readonly state: IPropsFromState & IComponentState = {
    formErrors: { searchInput: '' },
    searchInputValid: false,
    user: {},
    searchResults: {},
    modal: {},
  };

  constructor(props: AllProps) {
    super(props);
  }

  componentDidUpdate(prevProps: AllProps) {
    if (prevProps.searchResults.query !== this.props.searchResults.query) {
      this.handleSearch();
    }
  }

  componentDidMount() {
    auth.getProfile(() => {
      if (!auth.canUseSearch() || (!isAuthorizationBasedOnUserList() && auth.trialWillExpire())) {
        store.dispatch(showModal());
        store.dispatch(fetchSavedDocumentsRequest());
      }
    });
  }

  render() {
    if (this.props.user.isLoading) {
      return <Spinner />;
    } else {
      return (
        <div className="search-input">
          {auth.hasRole('Applicant') && <div className="applicant-info">Your email address has not been verified yet. Kindly open the email we've sent you to verify your email address and start using the application.</div>}
          <AuthorizationModal />

          {auth.canUseSearch() && (
            <>
              <SearchField />

              <div className="submit-actions">
                <div className="validation-errors">{this.state.formErrors.searchInput}</div>
              </div>
            </>
          )}
        </div>
      );
    }
  }

  private handleSearch() {
    const { searchResults } = this.props;
    const { selectedTab, query } = searchResults;
    const searchLimit = 30;

    if (query.trim().split(/\s+/).length < 1) {
      this.setState({ formErrors: { searchInput: 'Use at least 1 word' } });
      return;
    }

    const statuses: string[] = searchResults.filterConditions
      .filter((filter: any) => filter.key === 'topic_status')
      .map(({ value }: any) => value);
    switch (selectedTab) {
      case selectableTabs.FUNDING: {
        store.dispatch(clearTopicDescription());
        store.dispatch(saveQueryRequest({ query, scope: selectableTabs.FUNDING }));
        store.dispatch(searchRequest({ statuses, query, exclude: [], limit: searchLimit }));
        break;
      }
      case selectableTabs.NATIONAL: {
        store.dispatch(clearTopicDescription());
        store.dispatch(saveQueryRequest({ query, scope: selectableTabs.NATIONAL }));
        store.dispatch(nationalSearchRequest({ query, exclude: [], limit: searchLimit }));
        break;
      }
      case selectableTabs.PARTNERS: {
        store.dispatch(saveQueryRequest({ query, scope: selectableTabs.PARTNERS }));
        store.dispatch(partnerSearchRequest(query));
        break;
      }
      case selectableTabs.PROJECTS: {
        store.dispatch(saveQueryRequest({ query, scope: selectableTabs.PROJECTS }));
        store.dispatch(searchProjectsRequest({ query }));
        break;
      }
    }
  }
}

const AuthorizationModal = () => {
  if (!auth.canUseSearch() && auth.isUserUnAuthorizedBasedOnList()) {
    return (
      <div>
        <AccessNotAllowed />
        You do not have appropriate right to use the spinbase service
      </div>
    );
  } else if (!auth.canUseSearch() && auth.hasTrialExpired()) {
    return (
      <div>
        <TrialEnded />
        {getTextForExpiredTrailPeriod()}
      </div>
    );
  } else if (auth.trialWillExpire()) {
    return (
      <div>
        <TrialEnding />
      </div>
    );
  } else if (!auth.isEmailVerified()) {
    return (
      <div>
        <EmailNotVerified />
      </div>
    );
  } else {
    return null;
  }
};

const getTextForExpiredTrailPeriod = () => {
  if (auth.getTrialDaysLeft() === 0) {
    return (
      <div className="search-results--expired">
        {'Your trial period has ended today, to keep searching please'}
        <a className="subscribe" href="https://spinbase.eu/pricing/" target="blank">
          {'upgrade your subscription'}
        </a>
      </div>
    );
  }
  if (auth.getTrialDaysLeft() === -1) {
    return (
      <div className="search-results--expired">
        {'Your trial period has ended  a day ago, to keep searching please'}
        <a className="subscribe" href="https://spinbase.eu/pricing/" target="blank">
          {'upgrade your subscription'}
        </a>
      </div>
    );
  }
  return (
    <div className="search-results--expired">
      {`Your trial period has ended ${-1 * auth.getTrialDaysLeft()} days ago, to keep searching please`}
      <a className="subscribe" href="https://spinbase.eu/pricing/" target="blank">
        {'upgrade your subscription'}
      </a>
    </div>
  );
};

const mapStateToProps = ({ searchResults, user, modal, ...rest }: IState) => {
  return { searchResults, user, modal };
};

const mapDispatchToProps = (dispatch: any) => ({
  handleChange: (e: any) => {
    dispatch(setQuery({ query: e.target.value }));
  },
});

const SearchInput = withRouter(SearchInputComponent);

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
